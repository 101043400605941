export default class VariableTextModel {
  variableName = '';

  description = '';

  constructor(variableName: string, description: string) {
    this.variableName = variableName;
    this.description = description;
  }
}
