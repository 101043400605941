export default class VariableColumnModel {
  column = '';

  description = '';

  id = -1;

  position!: number;

  constructor(variableName: string, description: string) {
    this.column = variableName;
    this.description = description;
  }
}
