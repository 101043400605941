import { Transform } from 'class-transformer';
import { DateTransform } from '@/utils/value-transformers/date.transform';

export default class ClientManagementModel {
  managementName!: string;

  representantName!: string;

  situation!: string;

  reason!: string;

  observation!: string;

  @Transform(DateTransform)
  representantJustificationDate!: Date;

  representantJustification!: string;

  @Transform(DateTransform)
  managerJustificationDate!: Date;

  managerJustification!: string;
}
