import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import AuthGuard from '@/guards/auth.guard';
import { loadLanguageAsync, getCurrentLocale } from '@/i18n-setup';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/configurator',
    name: 'ConfigTemplateEmail',
    component: () => import('../views/configurator/config-template.vue'),
    meta: {
      title: 'Email Template Configuration',
    },
  },
  {
    path: '/configmetas',
    name: 'ConfigMetasCliente',
    component: () => import('../views/cr/metas-cliente.vue'),
    meta: {
      title: 'Configuração de metas por cliente',
    },
  },
  {
    path: '/crm',
    name: 'CrmIndex',
    redirect: '/crm/home',
    meta: { translation: 'crm' },
    component: () => import('../views/crm/index.vue'),
    children: [
      {
        name: 'CrmHome',
        path: 'home',
        component: () => import('../views/crm/home.vue'),
      },
      {
        name: 'CrmDashboard',
        path: 'dashboard/:clientId',
        component: () => import('../views/crm/dashboard.vue'),
      },
      {
        name: 'CrmCallCenterOverview',
        path: 'call-center/overview',
        component: () => import('../views/crm/call-center-overview.vue'),
      },
      {
        name: 'CrmOrders',
        path: 'orders/:clientId',
        component: () => import('../views/crm/orders.vue'),
      },
      {
        name: 'CrmFinancialInformations',
        path: 'financial-informations/:clientId',
        component: () => import('../views/crm/financial-informations.vue'),
      },
      {
        name: 'CrmProcesses',
        path: 'processes/:clientId',
        component: () => import('../views/crm/processes.vue'),
      },
      {
        name: 'CrmCalendar',
        path: 'calendar/:clientId',
        component: () => import('../views/crm/calendar.vue'),
      },
      {
        name: 'CrmActivities',
        path: 'activities/:clientId',
        component: () => import('../views/crm/activities.vue'),
      },
      {
        name: 'CrmActivityCalendarEventForm',
        path: 'activity-calendar-event-form',
        component: () => import('../views/crm/activity-calendar-event-form.vue'),
      },
      {
        name: 'CrmVisitsMade',
        path: 'visits-made/:clientId',
        component: () => import('../views/crm/visits-made.vue'),
      },
      {
        name: 'CrmSales',
        path: 'sales/:clientId',
        component: () => import('../views/crm/sales.vue'),
      },
      {
        name: 'CrmSalesErp',
        path: 'sales-erp/:clientId',
        component: () => import('../views/crm/sales-erp.vue'),
      },
      {
        name: 'CrmClientsManagement',
        path: 'clients-management/:clientId',
        component: () => import('../views/crm/clients-management.vue'),
      },
      {
        name: 'CrmEmails',
        path: 'emails/:clientId',
        component: () => import('../views/crm/emails.vue'),
      },
      {
        name: 'CrmChats',
        path: 'chats/:clientId',
        component: () => import('../views/crm/chats.vue'),
      },
      {
        name: 'CrmCalls',
        path: 'calls/:clientId',
        component: () => import('../views/crm/calls.vue'),
      },
      {
        name: 'CrmAttendance',
        path: 'attendance/:clientId',
        component: () => import('../views/crm/attendance.vue'),
      },
      {
        name: 'CrmChatOverview',
        path: 'chat-overview',
        component: () => import('../views/crm/chat-overview.vue'),
      },
      {
        name: 'CrmProspectCityClients',
        path: 'city-clients/:clientId',
        component: () => import('../views/crm/prospect-city-clients.vue'),
      },
    ],
  },
  {
    path: '/crm/statistics',
    name: 'CrmStatisticsIndex',
    meta: { translation: 'crm' },
    component: () => import('../views/crm/statistics.vue'),
  },
  {
    path: '/crm/close-chats',
    name: 'CrmCloseChats',
    meta: { translation: 'crm' },
    component: () => import('../views/crm/chats-close.vue'),
  },
  {
    path: '/crm/reports/chat',
    name: 'CrmChatReporIndex',
    meta: { translation: 'crm' },
    component: () => import('../views/crm/chat-report.vue'),
  },
  {
    path: '/crm/reports/email',
    name: 'CrmEmailReporIndex',
    meta: { translation: 'crm' },
    component: () => import('../views/crm/email-report.vue'),
  },
  {
    path: '/crm/reports/call',
    name: 'CrmCallReporIndex',
    meta: { translation: 'crm' },
    component: () => import('../views/crm/call-report.vue'),
  },
  {
    path: '/crm/global-calendar',
    name: 'CrmGlobalCalendar',
    meta: { translation: 'crm' },
    component: () => import('../views/crm/global-calendar.vue'),
  },
  {
    path: '/crm/attendant',
    name: 'CrmAttendantPanel',
    component: () => import('../views/crm/attendant.vue'),
    meta: {
      translation: 'crm',
      title: 'Painel do atendente',
    },
  },

  {
    path: '/gc',
    name: 'GCIndex',
    redirect: '/gc/configuration',
    component: () => import('../views/gc/index.vue'),
    children: [
      {
        name: 'GCConfigurationView',
        path: 'configuration',
        component: () => import('../views/gc/configuration/teamplate.vue'),
      },
    ],
  },
  {
    path: '/b2/audit',
    name: 'B2OrderAudit',
    meta: { translation: 'b2' },
    component: () => import('../views/b2/order-audit.vue'),
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    meta: {
      title: 'Forbidden!',
    },
    component: () => import('../views/errors/forbidden.vue'),
  },
  {
    path: '*',
    name: 'notFound',
    meta: {
      title: 'Not Found!',
    },
    component: () => import('../views/errors/not-found.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => new AuthGuard().resolve(to, from, next));

router.beforeEach((to, from, next) => {
  let translation = to.meta && to.meta.translation;

  if (!translation) {
    const parentRoute = to.matched.find((m) => m.meta.translation);
    translation = parentRoute && parentRoute.meta.translation;
  }

  loadLanguageAsync(getCurrentLocale(), translation).finally(() => next());
});

export default router;
