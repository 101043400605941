export enum SessionDataEnum {
  AdmOrRoot = 'admOrRoot',
  ApiToken = 'token',
  ApiUrl = 'apiUrl',
  SocketIOUrl = 'socketIOUrl',
  BuiltInMode = 'builtInMode',
  HideAppBar = 'hideAppBar',
  ClientType = 'clientType',
  OrderCode = 'orderCode',
  WebsocketCallCenter = 'websocketCallCenter',
  ExtensionCallCenter = 'extensionCallCenter',
  IdCallCenter = 'idCallCenter',
  ShowStatusCallCenterHeader = 'showStatusCallCenterHeader',
  PartnerApiCallCenter = 'partnerApiCallCenter',
  PartnerCodeCallCenter = 'partnerCodeCallCenter',
  ShowNotificationCallCenter = 'showNotificationCallCenter',
}
