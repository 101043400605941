import { injectable } from 'inversify-props';
import BaseService from '@/services/base-service';
import { RequestConfig } from '@/interfaces/request-config.interface';
import SintegraProspectModel from '@/models/sintegra-prospect.model';

@injectable()
export default class SintegraService extends BaseService {
  async getDataCrm(clientId: string, uf: string): Promise<SintegraProspectModel> {
    const config: RequestConfig = {
      params: {
        clientId,
        uf,
      },
    };
    const res = (await this.httpService.get(SintegraProspectModel,
      `${this.getApiPath()}/sintegra/consulta/crm`, config)) as SintegraProspectModel;
    return res;
  }
}
