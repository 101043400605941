import { Type } from 'class-transformer';

export default class ProcessModel {
  id!: number;

  idProcesso!: number;

  atendente!: string;

  @Type(() => Date)
  data!: Date;

  dataString!: string;

  etapa!: string;

  idFila!: number;

  fluxo!: string;

  idFluxo!: number;

  cliente!: string;

  cnpj!: string;

  tipoCliente!: string;

  descricao!: string;

  dataLimiteEtapa!: string;
}
